import Sketch from './module.js';

import $ from 'jquery';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger, SplitText);
gsap.registerPlugin(ScrollTrigger);


// let titles = [...document.querySelectorAll('h2')];

let sketch = new Sketch({
    dom: document.getElementById("container")
});

// let t1 = gsap.timeline();

// t1
//     .to(sketch.camera.position, { duration: 1,x: 6, y: 7 })
//     .to(sketch.camera.position, { duration: 1,x: 60, y: 70 })


// ScrollTrigger.create({
//       //animation: t1,
//     trigger: ".wrapper",
//     scrub: .1,
//     start: "top top",
//     end: "bottom bottom",
//     snap: 1 / (titles.length - 1),
//     onUpdate: (self) => {
//         // console.log(self.progress);
//         // console.log(sketch.model);

//         sketch.camera.position.y += 10* self.progress;
//         sketch.camera.position.x -= 10* self.progress;

//         // sketch.model.rotation.y = 2.*3.14 * self.progress;
//         // sketch.model.position.z = -2.5*Math.sin(3.14*self.progress);
//         //female Model
//         //sketch.model.position.y = -1.4+(1.5*Math.sin(3.14*self.progress));
//         //scarlett
//         //no .y movement as it is sittng on y=0

//     }
// });

// console.clear();

$("#sceneMain").on("click", function () {
    sketch.scene = sketch.sceneMain;
    console.log('Main');
})
$("#sceneGallery").on("click", function () {
    sketch.scene = sketch.sceneGallery;
    console.log('Gallery');
})

///////////////////////////////////////////////////////////////

const fadeOutActive = false;

const passSelection = (i, v) => {
    sketch.parameters.svgPartSelection = i;
    if (fadeOutActive) {
        if (v == 4) {
            document.getElementById("mplayerContainer").style.display = 'none';
            // console.log(document.getElementById("vitaIframe").style.display);
        }
        else if (v == 6) {
            document.getElementById("tutorialContainer").style.display = 'none';
            // console.log(document.getElementById("vitaIframe").style.display);
        }
        else if (v == 8) {
            document.getElementById("vitaIframe").style.display = 'none';
            // console.log(document.getElementById("vitaIframe").style.display);
        }
        // console.log(sketch.parameters.svgPartSelection);
    }
}

let sections = [...document.querySelectorAll('.section')];

const sectionsOpa = 1;

const desktopAnimation = () => {
    let section = 0;

    // passSelection(10);
    const tl = gsap.timeline({
        default: {
            // duration: 4,
            // ease: "power2.inOut"
        },
        scrollTrigger: {
            trigger: ".wrapper",
            // start: "top center-=5%",
            // end: "bottom bottom+=5%",
            start: "top top",
            end: "bottom bottom",
            scrub: 1,
            //  scroller: "smooth-scroll",
            snap: 1 / (sections.length - 1),
            // markers: true
        }
    })

    //Section 0 
    //7 0 1 5 2 3 4 9 6 8 10

    // EYESOUT VR 
    // GALLERY
    // Sound Visualizer
    // SOUND RECS
    // BUY / STORE
    // Tutorials
    // LBF
    // VITA
    // Contact
    // Imprint


    //Section 1 head
    //x= -left/+right y=+up/-down z=depth
    tl.to('.section00', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [7],
    });
    tl.to(sketch.camera.position, {
        x: 400, y: 700, z: 400,
    }, section);
    // tl.to(sketch.cameraTarget, { x: -600, y: -50 }, section);
    tl.to(sketch.cameraTarget, { x: 30, y: 250 }, section);
    tl.to('.section01', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [10]
    });


    //Section 2 knee left gallery
    section += 1;
    tl.to('.section01', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [4],
    });
    tl.to(sketch.camera.position, {
        x: -400, y: -300, z: 400,
    }, section);
    // tl.to(sketch.cameraTarget, { x: 150, y: -250 }, section);
    tl.to(sketch.cameraTarget, { x: 15, y: -250 }, section);
    tl.to('.section02', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [7]
    });


    //Section 3 neck left sound visualizer
    section += 1;
    tl.to('.section02', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [0],
    });
    tl.to(sketch.camera.position, {
        x: -800, y: 300, z: 800,
    }, section);
    // tl.to(sketch.cameraTarget, { x: 900, y: -250 }, section);
    tl.to(sketch.cameraTarget, { x: -80, y: -150 }, section);
    tl.to('.section03', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [4]
    });


    //Section 4 middle sound recs
    section += 1;
    tl.to('.section03', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [5],
    });
    tl.to(sketch.camera.position, {
        x: 0, y: -1000, z: 400,
    }, section);
    // tl.to(sketch.cameraTarget, { x: 0, y: -50 }, section);
    tl.to(sketch.cameraTarget, { x: 0, y: -50 }, section);
    tl.to('.section04', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [0, 4]
    });


    //Section 5 neck right STORE
    section += 1;
    tl.to('.section04', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [1, 4],
    });
    tl.to(sketch.camera.position, {
        x: 500, y: 300, z: 900,
    }, section);
    // tl.to(sketch.cameraTarget, { x: -600, y: -50 }, section);
    tl.to(sketch.cameraTarget, { x: 90, y: -50 }, section);
    tl.to('.section05', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [5]
    });


    //Section 06 foot right Tutorials
    section += 1;
    tl.to('.section05', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [8],
    });
    tl.to(sketch.camera.position, {
        x: 600, y: -450, z: 1220,
    }, section);
    // tl.to(sketch.cameraTarget, { x: -250, y: -150 }, section);
    tl.to(sketch.cameraTarget, { x: -25, y: -250 }, section);
    tl.to('.section06', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [1, 6]
    });


    //Section 07 arm left LbF
    section += 1;
    // console.log(document.getElementById("vitaIframe").style.display);
    tl.to('.section06', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [2, 6],
    });
    tl.to(sketch.camera.position, {
        x: -400, y: -900, z: 400,
    }, section);
    // tl.to(sketch.cameraTarget, { x: -300, y: -180 }, section);
    tl.to(sketch.cameraTarget, { x: -300, y: 180 }, section);
    tl.to('.section07', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [8]
    });


    //Section 8 knee right Vita
    section += 1;
    tl.to('.section07', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [9],
    });
    tl.to(sketch.camera.position, {
        x: 400, y: -100, z: 400,
    }, section);
    // tl.to(sketch.cameraTarget, { x: -150, y: -250 }, section);
    tl.to(sketch.cameraTarget, { x: -15, y: -250 }, section);
    tl.to('.section08', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [2, 8]
    });


    //Section 9 foot left Contact
    section += 1;
    tl.to('.section08', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [6, 8],
    });
    tl.to(sketch.camera.position, {
        x: -300, y: -500, z: 300,
    }, section);
    // tl.to(sketch.cameraTarget, { x: 150, y: -350 }, section);
    tl.to(sketch.cameraTarget, { x: 15, y: -450 }, section);
    tl.to('.section09', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [9]
    });


    //Section 10 arm right Tutorials
    section += 1;
    tl.to('.section09', {
        opacity: 0,
        onStart: passSelection, onStartParams: [3],
    });
    tl.to(sketch.camera.position, {
        x: 600, y: 300, z: 300,
    }, section);
    //  tl.to(sketch.cameraTarget, { x: 500, y: 50 }, section);
    tl.to(sketch.cameraTarget, { x: 350, y: 50 }, section);
    tl.to('.section10', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [6]
    });


}
// ScrollTrigger.create();
ScrollTrigger.matchMedia({ "(prefers-reduced-motion: no-preference)": desktopAnimation });


