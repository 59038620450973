uniform vec3 color; //3js Shadermaterial uniform
uniform sampler2D pointTexture; //3js Shadermaterial uniform

varying vec3 vColor; //color associated to vertices from vertex shader

void main() {
    //calculates the color for the particle
    gl_FragColor = vec4( color * vColor, 1.0);
    //sets a white particle color texture to a desired color
    gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );
}