uniform float time;
varying vec2 vUv;
varying vec3 vPosition;
varying float vAlpha;
uniform vec2 pixels;
attribute vec3 aTranslate;
attribute float aRotate;

mat4 rotationMatrix(vec3 axis, float angle) {
    axis = normalize(axis);
    float s = sin(angle);
    float c = cos(angle);
    float oc = 1.0 - c;
    
    return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                0.0,                                0.0,                                0.0,                                1.0);
}

vec3 rotate(vec3 v, vec3 axis, float angle) {
	mat4 m = rotationMatrix(axis, angle);
	return (m * vec4(v, 1.0)).xyz;
}

float PI = 3.141592653589793238;

void main(void) {
    //working with simple texture, not a sprite
    vUv = uv;
    // vUv = (uv - vec2(0.5))/3. + vec2(0.5 + 0.33, 0.5);
    //must be the same as in script tunnellänge
    float depth = 5000.;
    
    vec3 newpos = position;;

    newpos = rotate(newpos, vec3(0.,0.,1.), aRotate);
    newpos += aTranslate;
       

    // newpos.z = -mod(newpos.z - time * 100., 5.);
    // newpos.z = -newpos.z + time * 34.;
    newpos.z = mod(newpos.z + time * 34., -8000.);
     vPosition = newpos;
    //smothstep for smooth fade in ot the particles at the position where they come in
    //they come in at -5000. -> see line above newPos.z -> actually the same as "depth" variable
    vAlpha = smoothstep(-6000., -4000., newpos.z);

    // newpos.z = -newpos.z;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(newpos, 1.0);
}