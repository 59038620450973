uniform float time;
uniform float progress;
uniform sampler2D uTexture1;
uniform vec4 resolution;
varying vec2 vUv;
varying vec3 vPosition;
float PI = 3.141592653589793238;
varying float vAlpha;

void main() {

    // vec3 color = vec3(0.835, 0.000, 0.546);
    vec3 color = vec3(1.0);

    vec4 map = texture2D(uTexture1, vUv);
    //vec2newUV = (vUv -vec2(0.5))*resolution.zw + vec2(0.5);

    float opacity = smoothstep(500., 6000., length(vPosition.xy));

    if(map.r <0.01) discard;

    vec3 final = mix(vec3(1.), color, 1. - map.r);

    //gl_FragColor = vec4(1.,0.,0.,1.);
    gl_FragColor = vec4(vUv, 0.0, vAlpha);
    gl_FragColor = vec4(final, vAlpha*opacity*0.03);
    //  gl_FragColor = vec4(opacity);
}