/*tweaks for your models
you can change following variables to your liking
if svg is flipped upside down change next one
-> this.invertY in getData() to + or - 1
to make the trace paths longer change
-> this.lineLength in addObject() to a higher number, 100 was default in tutorial
to make the particles glow more intensively change
-> this.intensity in updateThings() to a lower number, 1000 was default in tutorial
the speed of the trails can be in-/decreased by the value 
-> "speed" in getdata(), IMPORTANT only integers, NO floats allowed
*/

import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { DoubleSide } from 'three';
import { Texture } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js';



import fragmentShader from '../shaders/fragment.glsl';
import vertexShader from '../shaders/vertex.glsl';

import partsBeamFragShader from '../shaders/partsBeamFragShader.glsl';
import partsBeamVertShader from '../shaders/partsBeamVertShader.glsl';

import nebulaFragShader from '../shaders/nebulaFrag.glsl'
import nebulaVertShader from '../shaders/nebulaVert.glsl'
import nebulaFBMFragShader from '../shaders/nebulaFBMFrag.glsl'
import nebulaFBMVertShader from '../shaders/nebulaFBMVert.glsl'

import laserEmitterFragShader from '../shaders/laserEmitterFragShader.glsl';
import laserEmitterVertShader from '../shaders/laserEmitterVertShader.glsl';

// import gsap from 'gsap';
import * as dat from "dat.gui"

// import bg_img from '../img/bg_img.jpg';
import bg_img from '../img/PCB_bg_transp.png';
import svgTexture from '../img/svgTexture.jpg';
import svgAlpha from '../img/svgAlpha_w.png';
import svgHDisp from '../img/svgHeightDisplacer.png';
import svgHDispInv from '../img/svgHeightDisplacer_inv.png';

import bg_px from '../img/skybox/Ico_nx.png';
import bg_py from '../img/skybox/Ico_ny.png';
import bg_pz from '../img/skybox/Ico_nz.png';
import bg_nx from '../img/skybox/Ico_px.png';
import bg_ny from '../img/skybox/Ico_py.png';
import bg_nz from '../img/skybox/Ico_pz.png';
import px from '../img/skybox/px_sm.jpg';
import py from '../img/skybox/py_sm.jpg';
import pz from '../img/skybox/pz_sm.jpg';
import nx from '../img/skybox/nx_sm.jpg';
import ny from '../img/skybox/ny_sm.jpg';
import nz from '../img/skybox/nz_sm.jpg';


const ringLightModelUrl = new URL('../assets/RingLight1.glb', import.meta.url);
const logoModelUrl = new URL('../assets/LogoExtruded_done.glb', import.meta.url);

import ringModelMatCap from '../img/matcap_test.png'

import cloudTexture from '../img/smoke.png';
import zmokeTexture from '../img/worms.png';
import partBeamTexture from '../img/logo_favicon.png';
import { LoadingManager } from 'three';



export default class Sketch {
  constructor(options) {

    this.stats;

    this.setMainRotation = .0;

    this.cameraTarget = new THREE.Vector3(0, 0, 0);

    this.parameters = {
      svgPartSelection: 10
    }

    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.point = new THREE.Vector2();



    this.debug = false;
    this.clickActive = false;
    this.showLogoBG = true;
    this.showRingLight = true;
    this.showLogoModel = true;
    this.showExtrudedSVG = false;
    this.showParticleBeam = false;
    this.showNebulaCloud = false;
    this.showPuppetString = true;
    this.showLaserString = false;
    this.doubleLaser = true;
    this.showFog = true;
    this.autoRotation = true;

    this.cloudParticles1 = [];
    this.cloudParticles2 = [];

    // this.laserUniforms;

    this.scene = new THREE.Scene();
    this.sceneMain = new THREE.Scene();
    this.sceneGallery = new THREE.Scene();

    //default scene to show on startup
    this.scene = this.sceneMain;

    //loadinng manger has to be added to every Loader(this.loadinngManager)
    this.loadingManager = new THREE.LoadingManager();
    // this.loadingManager.onStart = function(url, item, total) {
    //   console.log(`Started loading: ${url}`);
    // }
    const progressBar = document.getElementById('progress-bar');
    this.loadingManager.onProgress = function(url, loaded, total) {
      // console.log(`Started loading: ${url}`);
      progressBar.value = (loaded / total) *100;
    }
    const progressBarContainer = document.querySelector('.progress-bar-container')
    this.loadingManager.onLoad = function() {
      // console.log(`Just finished loading`);
      progressBarContainer.style.display = 'none';
    }
    // this.loadingManager.onError = function(url) {
    //   console.error(`Got a problem loading ${url}`);
    // }


    this.basicLightMaterial = new THREE.MeshBasicMaterial({ color: 0xE0C4A8 });
    this.basicDarkMaterial = new THREE.MeshBasicMaterial({ color: 0x6A4236 });

    this.reflectionCube = new THREE.CubeTextureLoader(this.loadingManager).load([px, nx, py, ny, pz, nz]);
    this.refractionCube = new THREE.CubeTextureLoader(this.loadingManager).load([px, nx, py, ny, pz, nz]);
    this.backgroundCube = new THREE.CubeTextureLoader(this.loadingManager).load([bg_px, bg_nx, bg_py, bg_ny, bg_pz, bg_nz]);

    this.container = options.dom;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    if (this.showFog) {
      this.sceneMain.fog = new THREE.FogExp2(0x111111, 0.00045);
      this.renderer.setClearColor(this.sceneMain.fog.color);
    } else {
      this.renderer.setClearColor(0x000000, 1.0);
    }
    // this.renderer.autoClearColor = false;
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    //stats
    if (this.debug) {
      this.stats = new Stats();
      this.stats.showPanel(0);
      var thisParent = document.getElementById("modal_4-content");
      thisParent.appendChild(this.stats.dom)
      var statsALL = document.getElementById("modal_4-content").querySelectorAll("canvas");
      for (var i = 0; i < statsALL.length; i++) {
        statsALL[i].style.width = "120%";
        statsALL[i].style.height = "120%";
        //...
      }
    }

    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      100,
      10000);

    // this.camera.position.set(400, 300, 1400);
    this.camera.position.set(-700, -500, 1600);
    this.camera.lookAt(0, 0, 0)
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    // if(this.autoRotation)this.controls.autoRotate = true;

    this.time = 0;

    this.isPlaying = false;
    this.getData();

    if (this.clickActive) this.mouseFunctions();

    this.showAllMain();

  }

  showAllMain() {

    this.container.appendChild(this.renderer.domElement);

    this.play();

    // this.addGUI(this.params);

    this.addBGCube();
    this.addParticleTrail();
    if (this.showRingLight) this.addRingLightModel();
    if (this.showLogoModel) this.addLogoModel();
    if (this.showNebulaCloud) this.addNebulaClouds(2, 200, 3500, 0.4, 0);
    if (this.showExtrudedSVG) this.addExtrudedSVG();
    if (this.showParticleBeam) this.addParticleBeam();
    if (this.showPuppetString) this.addPuppetStrings();

    // this.setupKeyControls();

    this.addLights();

    this.addGallery();

    this.resize();
    this.setupResize();
    this.render();
    //this.settings();


  }
  ////////////////////////////////////////////////////////////////////////////////////
  addBGCube() {

    this.BGGeometry = new THREE.SphereGeometry(1500, 13, 5);
    // Material
    this.BGMaterial = new THREE.MeshLambertMaterial({
      // side: THREE.BackSide,
      side: THREE.BackSide,
      envMap: this.backgroundCube,
      emissive: true,
      emissiveIntensity: 20
    });
    // // Mesh
    this.BGMesh = new THREE.Mesh(this.BGGeometry, this.BGMaterial);
    this.sceneMain.add(this.BGMesh);
  }

  ////////////////////////////////////////////////////////////////////////////////////

  addGallery() {

    this.geometryGallery = new THREE.SphereGeometry(100, 10, 10);
    this.materialGallery = new THREE.MeshNormalMaterial();

    this.meshGallery = new THREE.Mesh(this.geometryGallery, this.materialGallery);
    this.meshGallery.position.set(0, 0, 150);
    this.meshGallery.needsUpdate= true;
    this.sceneGallery.add(this.meshGallery); // so note need to be able to switch this on 
  }


  getData() {

    //to flip your model on Y-axis change next to plus or minus 1
    this.invertY = -1;

    this.svgParts = [...document.querySelectorAll('.cls-1')]

    this.lines = [];

    this.svgParts.forEach((path, j) => {
      // console.log(path.getTotalLength());
      let len = path.getTotalLength();

      let numberOfPoints = Math.floor(len / 5);
      //console.log(numberOfPoints);
      let points = [];

      for (let i = 0; i < numberOfPoints; i++) {
        let pointAt = len * i / numberOfPoints;
        let p = path.getPointAtLength(pointAt);
        let randX = (Math.random() - 0.5) * 1.3;
        let randY = (Math.random() - 0.5) * 1.3;
        // console.log(p);
        //invert .y value in following line to flip the svg otherwise it will be upside down
        points.push(new THREE.Vector3(p.x - 1024 + randX, this.invertY * (p.y - 512) + randY, 0));
      }

      this.lines.push({
        id: j,
        path: path,
        length: len,
        number: numberOfPoints,
        points: points,
        currentPos: 0,
        speed: 1

      });

    });
    // console.log(this.lines);
  }

  settings() {
    // let that = this;
    // this.settings = {
    //   progress: 0,
    // };
    // this.gui = new dat.GUI();
    // this.gui.add(this.settings, "progress", 0, 1, 0.01);
  }

  addGUI(controlObject) {

    // console.log(this.parameters.svgPartSelection)

    this.gui = new dat.GUI({
      width: 15 * 32 - 1
    })
    this.particleFolder = this.gui.addFolder('Particles')
    this.particleFolder.add(this.parameters, 'svgPartSelection').min(0).max(10).step(1).name('PathSelection');
    this.particleFolder.open()
    this.cameraFolder = this.gui.addFolder('Camera')
    this.cameraFolder.add(this.camera.position, 'z', 0, 10)
    // this.cameraFolder.open()
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;

    //image cover
    this.imageAspect = 853 / 1280;
    let a1; let a2;
    if (this.height / this.width > this.imageAspect) {
      a1 = (this.width / this.height) * this.imageAspect;
      a2 = 1
    } else {
      a1 = 1
      a2 = (this.height / this.width) * this.imageAspect;
    }

    this.material.uniforms.resolution.value.x = this.width;
    this.material.uniforms.resolution.value.y = this.height;
    this.material.uniforms.resolution.value.z = a1;
    this.material.uniforms.resolution.value.w = a2;

    if (this.showNebulaCloud) {
      this.nebulaMaterial.uniforms.u_resolution.value.x = this.width;
      this.nebulaMaterial.uniforms.u_resolution.value.y = this.height;
    }

    this.camera.updateProjectionMatrix();
  }


  addParticleTrail() {

    let that = this;
    //change next one for length of lines (= number of shown particles per trace)
    this.lineLength = 100;

    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: "f", value: 0 },
        resolution: { type: "v4", value: new THREE.Vector4() },
        uvRate1: {
          value: new THREE.Vector2(1, 1)
        }
      },
      //wireframe: true,
      transparent: true,
      depthTest: true,
      depthWrite: false,
      blending: THREE.AdditiveBlending,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });

    // this.geometry = new THREE.PlaneGeometry(1, 1, 10, 10);
    this.geometry = new THREE.BufferGeometry();

    this.max = this.lines.length * this.lineLength;
    // this.positions = [];
    this.positions = new Float32Array(this.max * 3);
    this.opacity = new Float32Array(this.max);

    for (let i = 0; i < this.max; i++) {
      this.opacity.set([Math.random() / 5], i);
      this.positions.set([Math.random() * 100, Math.random() * 1000, 0], i * 3);

    }

    this.geometry.setAttribute('position', new THREE.BufferAttribute(this.positions, 3));
    //console.log(this.positions);
    this.geometry.setAttribute('opacity', new THREE.BufferAttribute(this.opacity, 1));

    this.plane = new THREE.Points(this.geometry, this.material);
    this.plane.rotation.y = this.setMainRotation;
    this.plane.renderOrder = 999;
    this.sceneMain.add(this.plane);

    /////////////////////Logo BG////////////////////////////

    if (this.showLogoBG) {

      // let bg_texture = new THREE.TextureLoader(this.loadingManager).load(bg_img);
      let bg_texture = new THREE.TextureLoader(this.loadingManager).load(bg_img);
      bg_texture.flipY = true;

      let bg_displacer = new THREE.TextureLoader(this.loadingManager).load(svgHDisp);
      // let bg_displacer = new THREE.TextureLoader(this.loadingManager).load('svgHeightDisplacer.7cd22bfa.png')

      let bg_alphaMap = new THREE.TextureLoader(this.loadingManager).load(svgAlpha);

      const bg_geometry = new THREE.PlaneBufferGeometry(950, 995, 64, 64);

      const bg_material = new THREE.MeshStandardMaterial({
        color: 0xffffff,
        side: DoubleSide,
        map: bg_texture,
        // displacementMap: bg_displacer,
        //  displacementScale: -100,
        alphaMap: bg_alphaMap,
        opacity: .9,
        transparent: true,
        depthTest: true
      });

      this.logoBG = new THREE.Mesh(bg_geometry, bg_material);
      this.logoBG.renderOrder = 998;
      this.logoBG.position.set(0, 0, -1)
      // logoBG.rotation.x = 181;
      this.sceneMain.add(this.logoBG);



      // let bg = new THREE.Mesh(
      //   new THREE.PlaneBufferGeometry(950, 995, 64, 64),
      //   new THREE.MeshStandardMaterial({
      //     // color: 0x000011,
      //     map: bg_texture,
      //     displacementMap: bg_displacer,
      //     displacementScale: .9,
      //     // opacity: .2,
      //     // transparent: true
      //   }),
      // );
      // bg.renderOrder = 998;
      // this.sceneMain.add(bg);
    }



  }

  stop() {
    this.isPLaying = false;
  }

  play() {
    if (!this.isPLaying) {
      this.render();
      this.isPLaying = true;
    }
  }

  updateThings() {

    // if(this.autoRotation)this.controls.update();

    //particles on logo trail
    let j = 0;
    //change next one for overall light intensity of particles
    this.intensity = 1000;

    for (let e = 0; e < this.lines.length; e++) {

      this.lines[e].currentPos += this.lines[e].speed;
      this.lines[e].currentPos = this.lines[e].currentPos % this.lines[e].number;

      // console.log(line);
      for (let i = 0; i < this.lineLength; i++) {
        let index = (this.lines[e].currentPos + i) % this.lines[e].number;
        let p = this.lines[e].points[index];
        // console.log(pointNew);

        this.positions.set([p.x, p.y, p.z], j * 3);

        //magic happens here -> only the path that is selected should glow
        if (this.parameters.svgPartSelection < 10) {
          if (e == this.parameters.svgPartSelection) {
            this.opacity.set([i / this.intensity], j);
          } else {
            this.opacity.set([0], j);
          }
        } else {
          this.opacity.set([i / this.intensity], j);
        }

        j++;
      }
    }

    this.geometry.attributes.position.array = this.positions;
    this.geometry.attributes.position.needsUpdate = true;
    this.geometry.attributes.opacity.needsUpdate = true;

    //camera always looking at 0,0,0 without orbitcontrols and scrolltrigger
    this.camera.lookAt(this.cameraTarget);


    const rotSpeedX = 0.003;
    const rotSpeedY = 0.002;
    const rotSpeedZ = -0.01;


    //BG rotation

    if (this.BGMesh) this.BGMesh.rotation.x += 0.002;
    if (this.BGMesh) this.BGMesh.rotation.y += 0.001;

    //particle trail rotation
    //  this.plane.rotation.y += rotSpeedY*5;

    //logoBG rotation
    //  this.logoBG.rotation.y += rotSpeedY*5;

    // ringLight Model rotation

    // if(this.pointLight1) this.pointLight1.position.x += 10;
    // if(this.pointLight1) this.pointLight1.rotation.y += -0.003;
    // if(this.pointLight1) this.pointLight1.rotation.z += 0.003;

    if (this.ringLightObj) {
      this.ringLightObj.rotation.x += rotSpeedX;
      this.ringLightObj.rotation.y += rotSpeedY;
      this.ringLightObj.rotation.z += rotSpeedZ;
    }


    this.updatePuppetStrings(this.parameters.svgPartSelection, rotSpeedX, rotSpeedY, rotSpeedZ);

    // particles Beam on path
    if (this.showParticleBeam) {
      const beamPartDistance = 0.005 // change this one to de-/increase the distance between the particles
      this.partBeamPos = this.beamParticles.geometry.attributes.position;
      const time = .00003 * performance.now();

      for (let k = 0; k < this.beamPartAmount; k++) {
        const p = this.beamCurve.getPoint(time + beamPartDistance * k);
        this.partBeamPos.setXYZ(k, p.x, p.y, p.z);
      }
      this.partBeamPos.needsUpdate = true;
    }

  }

  updatePuppetStrings(showPart, rotX, rotY, rotZ,) {
    if (this.showPuppetString) {

      this.refBoxParent.rotation.x += rotX;
      this.refBoxParent.rotation.y += rotY;
      this.refBoxParent.rotation.z += rotZ;

      // this.refBox.rotation.x += rotSpeedX;
      // this.refBox.rotation.z += rotSpeedZ;
      this.refBoxInner.rotation.x += -rotX;
      this.refBoxInner.rotation.z += -rotZ;
      this.refTetraInner.rotation.x += -rotX;
      this.refTetraInner.rotation.z += -rotZ;

      var refBoxPosition = this.refBoxHook.map(box => {
        const boxPosition = new THREE.Vector3();
        box.getWorldPosition(boxPosition);
        return boxPosition;
      });

      var refBoxPositionPos = new THREE.Vector3(refBoxPosition[0].x, refBoxPosition[0].y, refBoxPosition[0].z);
      var refBoxPositionNeg = new THREE.Vector3(-(refBoxPosition[0].x), -(refBoxPosition[0].y), -(refBoxPosition[0].z));
      refBoxPositionPos.normalize();
      refBoxPositionPos.multiplyScalar(525)
      refBoxPositionNeg.normalize();
      refBoxPositionNeg.multiplyScalar(480)
      // console.log(refBoxPositionNeg);

      var points = [
        refBoxPositionPos,
        refBoxPositionNeg,
        new THREE.Vector3(this.positions[297], this.positions[298], this.positions[299]),
        new THREE.Vector3(this.positions[597], this.positions[598], this.positions[599]),
        new THREE.Vector3(this.positions[897], this.positions[898], this.positions[899]),
        new THREE.Vector3(this.positions[1197], this.positions[1198], this.positions[1199]),
        new THREE.Vector3(this.positions[1497], this.positions[1498], this.positions[1499]),
        new THREE.Vector3(this.positions[1797], this.positions[1798], this.positions[1799]),
        new THREE.Vector3(this.positions[2097], this.positions[2098], this.positions[2099]),
        new THREE.Vector3(this.positions[2397], this.positions[2398], this.positions[2399]),
        new THREE.Vector3(this.positions[2697], this.positions[2698], this.positions[2699]),
        new THREE.Vector3(this.positions[2997], this.positions[2998], this.positions[2999])
      ]

      //if show all parts
      if (showPart == 10) {
        if (this.doubleLaser) {
          for (let m = 0; m < this.lines.length * 2; m++) {
            this.sceneMain.remove(this.puppetStringArray[m]);
            //positive laser beams
            if (m < this.lines.length) {
              this.sceneMain.add(this.puppetStringArray[m]);
              this.puppetStringArray[m].geometry.setFromPoints([points[m + 2], points[0]]);
            }
            // negative laser beams
            if (m >= this.lines.length) {
              this.sceneMain.add(this.puppetStringArray[m]);
              // console.log(points[1])
              this.puppetStringArray[m].geometry.setFromPoints([points[m - this.lines.length + 2], points[1]]);;
              // this.puppetStringArray[m].geometry.setFromPoints([-1*points[0], points[m+1]]);
            }
          }
        }
        //if not double Laser
        else {
          for (let m = 0; m < this.lines.length; m++) {
            this.sceneMain.remove(this.puppetStringArray[m]);
            this.sceneMain.add(this.puppetStringArray[m]);
            this.puppetStringArray[m].geometry.setFromPoints([points[0], points[m + 2]]);
          }
        }
      }
      ///////////////////////////////////////////
      //if only show one part
      else {

        // this.sceneMain.remove(t.lines[j].line);
        if (this.doubleLaser) {
          for (let m = 0; m < this.lines.length * 2; m++) {
            // remove all lines
            this.sceneMain.remove(this.puppetStringArray[m]);
            //positive laser beams
            if (m < this.lines.length) {
              //if m is the line to be shown
              if (m == showPart) {
                this.sceneMain.add(this.puppetStringArray[m]);
                this.puppetStringArray[m].geometry.setFromPoints([points[m + 2], points[0]]);
              }

            }
            // negative laser beams
            if (m >= this.lines.length) {
              // console.log(points[1])
              if (m == showPart + this.lines.length) {
                this.sceneMain.add(this.puppetStringArray[m]);
                this.puppetStringArray[m].geometry.setFromPoints([points[m - this.lines.length + 2], points[1]]);;
                // this.puppetStringArray[m].geometry.setFromPoints([-1*points[0], points[m+1]]);
              }

            }
          }
        }
        //if not double Laser
        else {
          for (let m = 0; m < this.lines.length; m++) {
            this.sceneMain.remove(this.puppetStringArray[m]);
            if (m == showPart) {
              this.sceneMain.add(this.puppetStringArray[m]);
              this.puppetStringArray[m].geometry.setFromPoints([points[0], points[m + 2]]);
            }
          }
        }

      }
      /////////////////////////////////////////////////
    }
  }

  render() {
    if (!this.isPLaying) return;

    this.time += 0.05;

    this.updateThings();
    // this.ringLightObj.position.y += 10;
    // this.BGMesh.rotation.y += 0.01;
    this.material.uniforms.time.value = this.time;
    // this.nebulaMaterial.uniforms.time.value = this.time;
    if (this.showNebulaCloud) this.nebulaMaterial.uniforms.u_time.value = this.time;
    requestAnimationFrame(this.render.bind(this));
    this.renderer.render(this.scene, this.camera);
    if (this.debug) {
      this.stats.update();
    }

  }

  // addNebulaClouds(cloudParts, partsCount, piMult, piRot) {
  addNebulaClouds(cloudParts, partsCount, piRad, piMult, piRot) {
    let that = this;

    this.nebulaGeometry = new THREE.PlaneBufferGeometry(2, 2);
    //CylinderBufferGeom(radTop, radBottom, height, radialSegments, heightSegments, openEnded, thetaStart, thetaLength)
    // this.nebulaGeometry = new THREE.CylinderBufferGeometry(800, 800, 3000, 13, 1, true);

    this.nebulaMaterial = new THREE.ShaderMaterial({
      uniforms: {
        u_time: { type: "f", value: 1.0 },
        u_resolution: { type: "v2", value: new THREE.Vector2() },
        u_mouse: { type: "v2", value: new THREE.Vector2() }
      },
      vertexShader: nebulaFBMVertShader,
      fragmentShader: nebulaFBMFragShader,
      transparent: true,
      depthTest: false,
      depthWrite: false
    });

    this.nebulaMesh = new THREE.Mesh(this.nebulaGeometry, this.nebulaMaterial);
    this.nebulaMesh.renderOrder = 1;
    this.nebulaMesh.rotation.x = THREE.Math.degToRad(90);
    this.sceneMain.add(this.nebulaMesh);

  }

  addPuppetStrings() {

    //create all necessary objects (laser Emitters) and a parent mesh to store them onto

    const createLaserEmitterMesh = (type) => {
      if (type === 'Ico') {
        const box = new THREE.Mesh(
          new THREE.IcosahedronBufferGeometry(80),
          // this.ringLightMaterial2
          this.laserEmitterMaterial
        );
        return box;
      }
      if (type === 'Tetra') {
        const box = new THREE.Mesh(
          new THREE.TetrahedronBufferGeometry(80),
          // this.ringLightMaterial2
          this.laserEmitterMaterial
        );
        return box;
      }

    }

    this.refBox = createLaserEmitterMesh('Ico');
    this.refBox.position.y = 600;
    // this.refBox.rotation.x = .3;
    this.refBox.rotation.z = .55;

    this.refBoxHook = [this.refBox];

    this.refBoxInner = createLaserEmitterMesh('Ico');
    this.refBoxInner.position.y = 600;
    this.refBoxInner.scale.set(.5, .5, .5)

    this.refTetra = createLaserEmitterMesh('Tetra');
    this.refTetra.position.y = -545;
    this.refTetra.rotation.y = 0.8;
    this.refTetra.rotation.x = 2.2;
    // this.refTetra.scale.set(.5,.5,.5)

    this.refTetraInner = createLaserEmitterMesh('Tetra');
    this.refTetraInner.position.y = -545;
    this.refTetraInner.scale.set(.4, .4, .4)

    //add a parent Box container to store refBox
    this.refBoxParent = new THREE.Mesh();
    this.sceneMain.add(this.refBoxParent);

    //add the refBox to the parent container
    this.refBoxParent.add(this.refBox, this.refBoxInner, this.refTetra, this.refTetraInner);


    //create laser beams with array of lines

    this.lineMaterial = new THREE.LineBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: .6
    });

    this.puppetStringArray = [];

    if (this.doubleLaser) { var arrayLength = this.lines.length * 2; }
    else { arrayLength = this.lines.length; }

    for (let o = 0; o < arrayLength; o++) {
      this.puppetString = new THREE.Line(
        new THREE.BufferGeometry(),
        this.lineMaterial
      );
      this.puppetStringArray.push(this.puppetString);
      this.sceneMain.add(this.puppetStringArray[o]);
    }

  }

  addRingLightModel() {
    //////////////////////////////////add ringLight model///////////////////////
    // this.ringLightMaterial = new THREE.MeshPhongMaterial( { color: 0xffffff, envMap: textureCube, refractionRatio: 0.98 } );
    // this.ringLightMaterial1 = new THREE.MeshPhongMaterial( { color: 0xccddff, envMap: textureCube, refractionRatio: 0.98, reflectivity: 0.9 } );
    // this.ringLightMaterial2 = new THREE.MeshPhongMaterial( { color: 0xccfffd, envMap: textureCube, refractionRatio: 0.985 } );
    // this.ringLightMaterial3 = new THREE.MeshPhongMaterial( { color: 0xffffff, envMap: textureCube, refractionRatio: 0.98 } );

    //this.ringLightMaterial1 = new THREE.MeshLambertMaterial( { color: 0xff6600, envMap: reflectionCube, combine: THREE.MixOperation, reflectivity: 0.3 } );
    this.ringLightMaterial2 = new THREE.MeshLambertMaterial({
      color: 0xffffff,
      emissive: 0x2a2a2a,
      emissiveIntensity: 4,
      envMap: this.refractionCube,
      refractionRatio: 0.9,
      // side: THREE.DoubleSide,
    });

    // this.ringLightMaterial3 = new THREE.MeshMatcapMaterial({
    //   color: new THREE.Color().setHex(0x222222).convertSRGBToLinear(),
    //   matcap: new THREE.TextureLoader(this.loadingManager).load(ringModelMatCap),
    //   envMap: this.refractionCube,
    //   refractionRatio: .9,

    // })

    this.laserEmitterMaterial = new THREE.ShaderMaterial(
      {
        uniforms: {
          p: { type: "f", value: 2 },
          glowColor: { type: "c", value: new THREE.Color(0x84ccff) },
        },
        vertexShader: laserEmitterVertShader,
        fragmentShader: laserEmitterFragShader,
        side: THREE.DoubleSide,
        blending: THREE.AdditiveBlending,
        transparent: true,
        depthTest: true,
        depthWrite: false
      });


    //ringlight glb/gltf import

    const assetLoader = new GLTFLoader(this.loadingManager);
    this.ringLightObj;

    assetLoader.load(ringLightModelUrl.href, (gltf) => {
      this.ringLightObj = gltf.scene;
      const scaleFactor = 210;
      gltf.scene.scale.multiplyScalar(scaleFactor);

      gltf.scene.traverse(c => {
        c.material = this.ringLightMaterial2;
        // c.material = this.laserEmitterMaterial
        c.castShadow = true;
        c.receiveShadow = true;
        c.position.set(0, 0, 0);
      });
      // gltf.scene.add(wireframe);
      this.sceneMain.add(gltf.scene);

    });


  }


  addLogoModel() {
    //////////////////////////////////add extruded Blender Logo Model glb model///////////////////////

    this.logoVolumeMaterial = new THREE.MeshStandardMaterial({
      wireframe: false,
      color: 0xffffff,
      transparent: true,
      opacity: 0.2
    });;

    //logomodel  glb/gltf import
    const assetLoader = new GLTFLoader(this.loadingManager);

    this.logoBlenderObj;

    //helpful raycastre tutorial with additional loading code (isMesh or isLight)
    //https://sbcode.net/threejs/raycaster/

    assetLoader.load(logoModelUrl.href, (gltf) => {

      // this.modelHead = gltf.scene.getObjectByName('01_head');
      const scaleFactor = 3500;
      gltf.scene.scale.multiplyScalar(scaleFactor);

      gltf.scene.traverse(c => {
        if (c.isMesh) {
          // c.material = this.ringLightMaterial2;
          // c.material = this.laserEmitterMaterial
          c.material = this.logoVolumeMaterial,
            c.castShadow = true;
          c.receiveShadow = true;
          c.position.set(0, 0, -0.018);
          c.visible = false;
          // c.rotation.y = this.setMainRotation;
        }
        //     if(c.isLight){
        //       const l = c as THREE.Light
        // //         l.castShadow = true
        // //         l.shadow.bias = -0.003
        // //         l.shadow.mapSize.width = 2048
        // //         l.shadow.mapSize.height = 2048
        //     }
      });

      // this.sceneMain.add(this.logoGroup);
      this.logoBlenderObj = gltf.scene;
      // const modelArray = gltf.scene.children;
      this.sceneMain.add(gltf.scene);
      // console.log(this.modelArray);
      // this.addRaycaster(this.camera, gltf.scene.children);
    },

      (xhr) => {
        // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
      },
      (error) => {
        console.log(error)
      })



  }

  addParticleBeam() {
    //from here
    //https://www.youtube.com/watch?v=gB8-z1bjcAA
    this.beamCurve = new THREE.CatmullRomCurve3([
      new THREE.Vector3(-1000, 250, 0),
      new THREE.Vector3(-800, 500, 0),
      new THREE.Vector3(900, 500, 0),
      new THREE.Vector3(110, 250, 0),
      new THREE.Vector3(900, -50, 0),
      new THREE.Vector3(-800, -50, 0),
    ], true, // // closed curve: true = loop, false = not a loop, open curve)
      "centripetal", // curveType: centripetal (default), chordal, catmullrom
    );

    //number of pieces to divide curve into (deFULT = 5)
    this.vertices = this.beamCurve.getSpacedPoints(80);
    console.log(this.vertices);

    //CREATE CURVED LINE
    this.beamLineGeometry = new THREE.BufferGeometry().setFromPoints(this.vertices);
    this.beamLineMaterial = new THREE.LineBasicMaterial({ color: 0xffff00, visible: true });
    this.beamLine = new THREE.Line(this.beamLineGeometry, this.beamLineMaterial);
    this.sceneMain.add(this.beamLine);

    //create particles
    this.beamPartAmount = 100; //numer of particles
    this.beamPartPositions = new Float32Array(this.beamPartAmount * 3); //creeate array of 30 (10*3) zeros for x,y,z positions of particles
    console.log(this.beamPartPositions);

    this.beamPartColors = []; //arreay for particle colors
    this.beamPartColor = new THREE.Color(0xffffff); //create a color to change for each particle in loop below
    this.beamPartSizes = []; //array for particle sizes

    for (let i = 0; i < this.beamPartAmount; i++) {
      // this.beamPartColor.setHSL(1.0 * (i/this.beamPartAmount), 0.9, 0.5);
      this.beamPartColor.setHSL(1.0, 1.0, 1.0);
      this.beamPartColor.toArray(this.beamPartColors, i * 3); //stores color as an array

      this.beamPartSizes.push(50); //particle size
    }

    //particle geometry
    this.partBeamGeometry = new THREE.BufferGeometry();
    this.partBeamGeometry.setAttribute('position', new THREE.BufferAttribute(this.beamPartPositions, 3));
    this.partBeamGeometry.setAttribute('customColor', new THREE.Float32BufferAttribute(this.beamPartColors, 3));
    this.partBeamGeometry.setAttribute('size', new THREE.Float32BufferAttribute(this.beamPartSizes, 1));

    const uniforms = {
      color: { value: new THREE.Color(0xffffff) }, //particle color
      pointTexture: { value: new THREE.TextureLoader(this.loadingManager).load(partBeamTexture) } //particle texure
    }

    //particle material
    this.partBeamMaterial = new THREE.ShaderMaterial({
      uniforms,
      vertexShader: partsBeamVertShader,
      fragmentShader: partsBeamFragShader,
      blending: THREE.AdditiveBlending,
      depthTest: true,
      transparent: true
    });

    this.beamParticles = new THREE.Points(this.partBeamGeometry, this.partBeamMaterial);
    console.log(this.beamParticles);
    this.sceneMain.add(this.beamParticles);


  }

  addExtrudedSVG() {

    ///////////////////////////////////add extruded svg////////////////////////////////////

    //extrude svg paths to model
    //from here
    //https://muffinman.io/blog/three-js-extrude-svg-path/

    if (this.showExtrudedSVG) {
      this.svgMarkup = document.querySelector('svg').outerHTML;
      this.loader = new SVGLoader(this.loadingManager);
      this.svgData = this.loader.parse(this.svgMarkup);

      this.svgGroup = new THREE.Group();
      // When importing SVGs paths are inverted on Y axis
      // it happens in the process of mapping from 2d to 3d coordinate system
      this.svgGroup.scale.y *= -1;

      // Loop through all of the parsed paths
      this.svgData.paths.forEach((path, i) => {
        this.shapes = path.toShapes(true);

        // Each path has array of shapes
        this.shapes.forEach((shape, j) => {
          // Finally we can take each shape and extrude it
          this.svgGeometry = new THREE.ExtrudeGeometry(shape, {
            depth: -150,
            bevelEnabled: false,
            DoubleSide: true,
            bevelThickness: 0.2,
            bevelSize: 0.2,
            bevelSegments: 8,
            material: 0,
            extrudeMaterial: 1
          });

          // this.svgMaterial = new THREE.MeshLambertMaterial({
          //   color: 0x00afaf,
          //   // emissive: 0x2a2a2a,
          //   // emissiveIntensity: .5,
          //   side: THREE.DoubleSide
          // })
          this.svgMaterial = this.ringLightMaterial2;
          // Create a mesh and add it to the group
          this.svgMesh = new THREE.Mesh(this.svgGeometry, this.svgMaterial);

          this.svgMesh.castShadow = true;
          this.svgMesh.receiveShadow = true;

          this.svgGroup.add(this.svgMesh);
        });
      });

      // Meshes we got are all relative to themselves
      // meaning they have position set to (0, 0, 0)
      // which makes centering them in the group easy

      // Get group's size
      this.svgBox = new THREE.Box3().setFromObject(this.svgGroup);
      this.svgSize = new THREE.Vector3();
      this.svgBox.getSize(this.svgSize);

      this.svgOffsetY = this.svgSize.y / -1.93;
      this.svgOffsetX = this.svgSize.x / -0.93;
      //next should be half of the extrusion rate from above -> depth - 150
      this.svgOffsetZ = this.svgSize.z - 75;

      // Offset all of group's elements, to center them
      this.svgGroup.children.forEach(item => {
        item.position.x = this.svgOffsetX;
        item.position.y = this.svgOffsetY;
        item.position.z = this.svgOffsetZ;
      });

      // Finally we add svg group to the scene
      this.sceneMain.add(this.svgGroup);

    }
  }

  addLights() {

    this.ambientLight = new THREE.AmbientLight(0xffffff);
    this.sceneMain.add(this.ambientLight);


    //from the nebulaCloud Ttutorial
    // this.pointLight1 = new THREE.PointLight(0x3677ac, 350, 500, 1.0);
    // this.pointLight1.position.set(-1200, 0, 200);
    // this.sceneMain.add(this.pointLight1);
    // this.pointLight2 = new THREE.PointLight(0xd8547e, 350, 550, 0.3);
    // this.pointLight2.position.set(1200, 0, 0);
    // this.sceneMain.add(this.pointLight2);
    // this.pointLight3 = new THREE.PointLight(0x3677ac,50,450,1.7);
    // this.pointLight3.position.set(-1050,150,200);
    // this.sceneMain.add(this.pointLight3);

    // this.pl1Helper = new THREE.PointLightHelper(this.pointLight1);
    // this.sceneMain.add(this.pl1Helper);

  }





  mouseFunctions() {
    window.addEventListener('mouseup', (event) => {
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
      this.raycaster.setFromCamera(this.mouse, this.camera);

      // console.log('there')

      var intersects = this.raycaster.intersectObjects(this.sceneMain.children, true);

      if (intersects.length > 0) {

        //     // for (var i = 0; i < intersects.length; i++) {
        //     // console.log(intersects[i]);
        //     /*
        //         An intersection has the following properties :
        //             - object : intersected object (THREE.Mesh)
        //             - distance : distance from camera to intersection (number)
        //             - face : intersected face (THREE.Face3)
        //             - faceIndex : intersected face index (number)
        //             - point : intersection point (THREE.Vector3)
        //             - uv : intersection point in the object's UV coordinates (THREE.Vector2)
        //     */
        // console.log(intersects[0].object.name)

        if (intersects[0].object.name == '01_head') {
          this.parameters.svgPartSelection = 7;
          if (this.debug) console.log(this.parameters.svgPartSelection);
          this.hideLogoParts();
          // intersects[0].object.visible = !intersects[0].object.visible;
          intersects[0].object.visible = true;


        } else if (intersects[0].object.name == '02_neck_L') {
          this.parameters.svgPartSelection = 0;
          if (this.debug) console.log(this.parameters.svgPartSelection)
          this.hideLogoParts();
          intersects[0].object.visible = true;

        } else if (intersects[0].object.name == '03_neck_R') {
          this.parameters.svgPartSelection = 1;
          if (this.debug) console.log(this.parameters.svgPartSelection)
          this.hideLogoParts();
          intersects[0].object.visible = true;

        } else if (intersects[0].object.name == '04_arm_L') {
          this.parameters.svgPartSelection = 2;
          if (this.debug) console.log(this.parameters.svgPartSelection)
          this.hideLogoParts();
          intersects[0].object.visible = true;

        } else if (intersects[0].object.name == '05_body') {
          this.parameters.svgPartSelection = 5;
          if (this.debug) console.log(this.parameters.svgPartSelection)
          this.hideLogoParts();
          intersects[0].object.visible = true;

        } else if (intersects[0].object.name == '06_arm_R') {
          this.parameters.svgPartSelection = 3;
          if (this.debug) console.log(this.parameters.svgPartSelection)
          this.hideLogoParts();
          intersects[0].object.visible = true;

        } else if (intersects[0].object.name == '07_hip_L') {
          this.parameters.svgPartSelection = 4;
          if (this.debug) console.log(this.parameters.svgPartSelection)
          this.hideLogoParts();
          intersects[0].object.visible = true;

        } else if (intersects[0].object.name == '08_hip_R') {
          this.parameters.svgPartSelection = 9;
          if (this.debug) console.log(this.parameters.svgPartSelection)
          this.hideLogoParts();
          intersects[0].object.visible = true;

        } else if (intersects[0].object.name == '09_foot_L') {
          this.parameters.svgPartSelection = 6;
          if (this.debug) console.log(this.parameters.svgPartSelection)
          this.hideLogoParts();
          intersects[0].object.visible = true;

        } else if (intersects[0].object.name == '10_foot_R') {
          this.parameters.svgPartSelection = 8;
          if (this.debug) console.log(this.parameters.svgPartSelection)
          this.hideLogoParts();
          intersects[0].object.visible = true;

        } else {
          this.parameters.svgPartSelection = 10;
          if (this.debug) console.log(this.parameters.svgPartSelection)
          this.hideLogoParts();
          intersects[0].object.visible = true;
        }

      }
    }, false);
  }

  hideLogoParts() {
    for (let i = 0; i < this.logoBlenderObj.children.length; i++) {
      this.logoBlenderObj.children[i].visible = false;
    }
  }

  // setupKeyControls() {
  //   // var cube = scene.getObjectByName('cube');
  //   document.addEventListener("keydown", onDocumentKeyDown, false);
  //   function onDocumentKeyDown(event) {
  //     var keyCode = event.key;
  //     if (keyCode == 0) {
  //       this.parameters.svgPartSelection = 0;
  //       console.log(this.parameters.svgPartSelection);

  //     } else if (keyCode == 1) {
  //       this.parameters.svgPartSelection = 1;
  //       console.log(this.parameters.svgPartSelection);

  //     } else if (keyCode == 2) {
  //       this.parameters.svgPartSelection = 2;
  //       console.log(this.parameters.svgPartSelection);

  //     } else if (keyCode == 3) {
  //       this.parameters.svgPartSelection = 3;
  //       console.log(this.parameters.svgPartSelection);

  //     } else if (keyCode == 4) {
  //       this.parameters.svgPartSelection = 4;
  //       console.log(this.parameters.svgPartSelection);

  //     } else if (keyCode == 5) {
  //       this.parameters.svgPartSelection = 5;
  //       console.log(this.parameters.svgPartSelection);

  //     } else if (keyCode == 6) {
  //       this.parameters.svgPartSelection = 5;
  //       console.log(this.parameters.svgPartSelection);

  //     } else if (keyCode == 7) {
  //       this.parameters.svgPartSelection = 7;
  //       console.log(this.parameters.svgPartSelection);

  //     } else if (keyCode == 8) {
  //       this.parameters.svgPartSelection = 8;
  //       console.log(this.parameters.svgPartSelection);

  //     } else if (keyCode == 9) {
  //       this.parameters.svgPartSelection = 9;
  //       console.log(this.parameters.svgPartSelection);

  //     }
  //     // else if (keyCode == Digit) {
  //     //   this.parameters.svgPartSelection = 10;
  //     // } else if (keyCode == Digit) {
  //     // thius.svgPartSelect = 12
  //     // }
  //   };
  // }

}

// new Sketch({
//   dom: document.getElementById("container")
// });

